import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';
import setLabel from '@atoms/form/funcs/setLabel';

import { Form, DatePicker, Input } from 'antd';
const { RangePicker } = DatePicker;

class FormDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      label: '',
      required: true,
      size: defaults.antd.input.size,
      placeholder: false,
      minDate: 36500, // 100 years
      maxDate: 1,
      userLocalDate: defaults.date.localDate,
      format: defaults.date.serverDate,
    };

    this.disabledDate = this.disabledDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
    this.onRangePick = this.onRangePick.bind(this);
  }

  componentDidMount() {
    const { t, item } = this.props;

    let format = defaults.date.serverDate;
    let userLocalDate = defaults.date.defaultDate;

    if (item.format) {
      format = defaults.date[`server${item.format}`];

      if (item.format === 'DateTime') {
        userLocalDate = defaults.date.localDateTime24;
      }
    }

    const label = setLabel(item, t);

    this.setState({
      ...item,
      format: format,
      userLocalDate: userLocalDate,
      extra: t(`field.${item.name}.extra`, false),
      label: label,
      placeholder: !!item.placeholder,
    });
  }

  componentDidUpdate(prevProps) {
    const { _static, isRange } = this.props;

    if (!_static && this.props.disabled !== prevProps.disabled) {
      this.handleChange(isRange ? [] : '');
    }
  }

  /**
   *
   * Min date 'current - 100 years'
   * Max date 'current - 1 day'
   * @param {*} current
   * @returns {bool} allowed dates
   *
   */
  disabledDate(current) {
    const minDate = current < moment().subtract(this.state.minDate, 'day');
    const maxDate = current > moment().subtract(this.state.maxDate, 'day');

    return current && (minDate || maxDate);
  }

  /**
   *
   * Calls parrent change method
   * @param {*} momentDate
   * @param {string} formatedDate
   *
   */
  handleChange(momentDate) {
    const { format } = this.state;
    const { _static, item, isRange } = this.props;

    if (_static) {
      return false;
    }

    let _value = null;

    if (isRange) {
      _value = [
        momentDate[0] ? momentDate[0].format(format) : null,
        momentDate[1] ? momentDate[1].format(format) : null,
      ];
    }

    if (!isRange) {
      _value = momentDate ? momentDate.format(format) : null;
    }

    const newItem = {
      ...item,
      value: _value,
      help: '',
      status: '',
    };

    this.props.onChange(newItem);
  }

  onRangePick(momentDate) {
    const { isRange } = this.props;

    if (!isRange) {
      return false;
    }

    this.handleChange(momentDate);
  }

  /**
   *
   * @param {bool} state
   *
   */
  toggleFocus(state) {
    this.setState({ focused: state });
  }

  render() {
    const { focused, label, extra, size, placeholder, userLocalDate, format } = this.state;
    const { _static, disabled, loading, item, isRange } = this.props;
    const { value, status, help, name, required } = item;

    const classes = [];
    let momentDate = null;
    let formatedDate = '';
    let _value = isRange ? [null, null] : null;
    let staticValue = '';

    if (isRange) {
      momentDate = [
        value[0] ? moment(value[0], format) : null,
        value[1] ? moment(value[1], format) : null,
      ];

      formatedDate = `${momentDate[0] ? momentDate[0].format(format) : ''}${
        momentDate[1] ? `,${momentDate[1].format(format)}` : ''
      }`;

      _value = momentDate;

      classes.push('isRange');

      if (_static) {
        staticValue = `${_value[0] ? _value[0].format(userLocalDate) : ''}${
          _value[0] && _value[1] ? ' - ' : ''
        }${_value[1] ? _value[1].format(userLocalDate) : ''}`;
      }
    }

    if (!isRange) {
      momentDate = moment(value, format);

      formatedDate = value ? momentDate.format(format) : '';

      _value = value ? momentDate : null;

      if (_static) {
        staticValue = _value ? _value.format(userLocalDate) : '';
      }
    }

    let _placeholder = false;

    if (placeholder) {
      _placeholder = label ? label : userLocalDate;
    }

    if (_static) {
      classes.push('isStatic');
    }

    if (focused) {
      classes.push('isFocused');
    }

    if (required) {
      classes.push('isRequired');
    }

    if (
      (_value && value !== 'Invalid date') ||
      (isRange && value[0] !== 'Invalid date' && value[1] !== 'Invalid date') ||
      _static
    ) {
      classes.push('notEmpty');
    }

    const maxDate = moment().subtract(this.state.maxDate, 'day');
    const defaultPickerValue = moment().diff(maxDate) > 0 ? maxDate : moment();

    const base_config = {
      allowClear: true,
      showTime: ['DateTime', 'DateTime24'].includes(item.format),
      style: { width: '100%' },
      format: userLocalDate,
      defaultValue: _value,
      value: _value,
      status: status,
      disabledDate: this.disabledDate,
      defaultPickerValue,
      onChange: this.handleChange,
      onFocus: () => this.toggleFocus(true),
      onBlur: () => this.toggleFocus(false),
      onCalendarChange: this.onRangePick,
      disabled: disabled || loading,
      size: size,
      placeholder: '',
    };

    return (
      <Form.Item
        extra={extra}
        validateStatus={status}
        status={status}
        help={help}
        required={required}
        className={classes.join(' ')}
      >
        {!_static && !isRange && <DatePicker {...base_config} />}

        {!_static && isRange && <RangePicker {...base_config} separator={'-'} />}

        {_static && (
          <Form.Item>
            <Input type="text" value={staticValue} size={size} disabled={true} />
          </Form.Item>
        )}

        {_placeholder && (
          <div className="inputLabel">
            {required && !value && <div className="fieldIsRequired">*</div>}

            <div className="animatedPlaceholder">{_placeholder}</div>
          </div>
        )}

        <input name={name} type="hidden" value={formatedDate} />
      </Form.Item>
    );
  }
}

export default tConsume(FormDate);

FormDate.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  _static: PropTypes.bool,
  isRange: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

FormDate.defaultProps = {
  disabled: false,
  loading: false,
  isRange: false,
};
