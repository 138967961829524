import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Information } from 'IMAGE';
import Tooltip from './tooltip';
import Popup from './popup';
import { tConsume } from 'TRANSLATION';
import Content from './content';

class Hint extends Component {
  render() {
    const { t, type, content, data } = this.props;

    const icon = <Information />;
    let _content = content;

    if (typeof _content === 'string' && _content in Content) {
      _content = Content[_content](data);
    }

    const hints = {
      tooltip: Tooltip,
      popup: Popup,
    };

    const _Hint = hints[type in hints ? type : 'tooltip'](icon, _content, t, data);

    return <span className="Hint">{_Hint}</span>;
  }
}

export default tConsume(Hint);

Hint.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string,
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
};

Hint.defaultProps = {
  type: 'tooltip',
};
