import { responseError } from 'FIELDS';

const withNotificationForError = (payloadCreator) => {
  return async (args, thunkAPI) => {
    try {
      return await payloadCreator(args, thunkAPI);
    } catch (error) {
      responseError(error);
      throw error;
    }
  };
};

const checkPermissions = (menuItems, acl) => {
  let filtered = menuItems.filter(
    (item) => !item.permissions || item.permissions.every((permission) => acl.includes(permission))
  );

  filtered.map((item) => {
    if (item.subItems) {
      item.subItems = checkPermissions(item.subItems, acl);
    }

    return item;
  });

  filtered = filtered.filter((item) => !item.subItems || item.subItems.length);

  return filtered;
};

export { withNotificationForError, checkPermissions };
