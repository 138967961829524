import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getFormData from 'get-form-data';
import api from 'API';
import axios from 'axios';

import { InputsSet } from 'INPUTS';
import defaults from 'DEFAULTS';
import { Button, Form } from 'antd';

import { fieldValidation, fieldChange, responseError } from 'FIELDS';
import { tConsume } from 'TRANSLATION';
import { Strings } from 'HELPERS';
import EmailChange3step from '@organisms/personal-profile/EmailChange/EmailChange3step';

class EmailChange2step extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: 'email-code',
      waitingCode: '',
      counter: defaults.timer,
      data: {},
      code: {
        name: 'code',
        type: 'number',
        accept: defaults.re.digits,
        required: true,
        placeholder: true,
      },
    };

    this._counterInterval = null;

    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.resetCounter = this.resetCounter.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.resetCounter();
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this._counterInterval);
  }

  /**
   *
   * Back counter
   *
   */
  resetCounter(counter = defaults.timer) {
    this.setState({ counter: counter });

    this._counterInterval = setInterval(() => {
      this.setState({ counter: --counter });

      if (counter === 0) {
        clearInterval(this._counterInterval);
      }
    }, 1000);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { code } = this.state;
    const { userType } = this.props;

    const data = {
      ...getFormData(event.target, { trim: true }),
      ...this.props.data,
      ...this.state.data,
    };

    if (this.fieldValidation()) {
      this.setState({
        loading: true,
      });

      axios(api.users.profile.changeEmailVerify(userType).AXIOS('POST', data))
        .then((response) => {
          if (this._mounted) {
            this.setState({
              data: {
                ...data,
                code: code.value,
              },
              redirect: true,
              waitingCode: response.data.auth_type,
            });
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  resendCode(event) {
    const { data } = this.props;

    this.props.confirmationCodeRequest(event, data);
    this.resetCounter();
  }

  render() {
    const { loading, waitingCode, data, counter } = this.state;
    const { t, userType, toggleModal } = this.props;

    if (waitingCode) {
      return <EmailChange3step userType={userType} data={data} toggleModal={toggleModal} />;
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <div className={'mb20 align-center'}>
          {Strings.Compile(t('component.email.checkInbox'), [this.props.data.email])}
        </div>

        {InputsSet(this, ['code'])}

        <p className={'align-center mb40 textDescription'}>
          {`${Strings.Compile(t('component.email.descriptionResent'), [counter])} `}
          <a onClick={this.resendCode} disabled={counter !== 0}>
            {t('component.phoneNumber.reSend')}
          </a>
        </p>

        <Button
          type={'primary'}
          htmlType={'submit'}
          size={defaults.antd.button.size}
          loading={loading}
          className={'mb20'}
          block
        >
          {t('button.continue')}
        </Button>
      </Form>
    );
  }
}

export default tConsume(EmailChange2step);

EmailChange2step.propTypes = {
  userType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmationCodeRequest: PropTypes.func,
  t: PropTypes.func.isRequired,
};
