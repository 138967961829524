import React, { Component } from 'react';
import { Spin, Icon } from 'antd';

export default class Spinner extends Component {
  render() {
    const antIcon = <Icon type="loading" spin />;

    return (
      <div className="spinner">
        <Spin indicator={antIcon} />
      </div>
    );
  }
}
