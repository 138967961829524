import React from 'react';
import { Modal } from 'antd';

const popup = (icon, content, t) => {
  const config = {
    content: content,
    destroyOnClose: true,
    okText: t('button.ok'),
    width: 600,
    className: 'HintPopup',
  };

  const showPopup = () => {
    Modal.info(config);
  };

  return <span onClick={showPopup}>{icon}</span>;
};

export default popup;
