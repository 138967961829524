import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { useSelector, useDispatch } from 'react-redux';

import { sessionUpdate, sessionVerify } from '@features/sessionSlice';
import { fetchUnreadMessages } from '@features/casesSlice';
import Spinner from '@atoms/spinner';
import Wrapper from '@templates/dashboard/Wrapper';
import PagePersonalProfile from '@templates/profile/Personal';
import PageNotFound from '@templates/page-not-found';
import SessionTimeout from '@molecules/session-timeout/SessionTimeout';

import defaults from 'DEFAULTS';
import { PageRoute, tConsume } from 'TRANSLATION';

const AsyncRegistrationCompany = Loadable({
  loader: () => import('../auth/registration-company'),
  loading: Spinner,
  delay: 60,
});

const AsyncRegistrationIndividual = Loadable({
  loader: () => import('../auth/registration-individual'),
  loading: Spinner,
  delay: 60,
});

const AsyncIndividual = Loadable({
  loader: () => import('./individual/index'),
  loading: Spinner,
  delay: 60,
});

const AsyncCompany = Loadable({
  loader: () => import('./company/index'),
  loading: Spinner,
  delay: 60,
});

const AsyncAemi = Loadable({
  loader: () => import('./aemi/index'),
  loading: Spinner,
  delay: 60,
});

const DashboardPage = ({ route, userType, verified }) => {
  const [status, setStatus] = useState(null);
  const acl = useSelector((state) => state.acl.data.acl);
  const dispatch = useDispatch();

  const routePersonalProfile = `${route}/profile`;
  const verificationPages = ['aemi'].includes(userType);

  useEffect(() => {
    if (verified || verificationPages) {
      setStatus('done');
    }

    dispatch(sessionUpdate());
    const sessionInterval = setInterval(() => {
      dispatch(sessionVerify());
    }, defaults.timer * 1000);

    dispatch(fetchUnreadMessages());
    const unreadMessageInterval = setInterval(() => {
      dispatch(fetchUnreadMessages());
    }, defaults.messageTimer * 1000);

    return () => {
      clearInterval(sessionInterval);
      clearInterval(unreadMessageInterval);
    };
  }, []);

  const renderRegistration = () => {
    if (userType === 'company') {
      return (
        <AsyncRegistrationCompany
          userType={userType}
          route={route}
          sessionUpdate={() => dispatch(sessionUpdate())}
        />
      );
    }

    return (
      <AsyncRegistrationIndividual
        userType={userType}
        route={route}
        sessionUpdate={() => dispatch(sessionUpdate())}
      />
    );
  };

  if (!verified && status !== 'done' && !verificationPages) {
    return (
      <>
        <SessionTimeout userType={userType} />

        <Switch>
          <Route path={route} render={renderRegistration} />

          <Route render={() => <PageNotFound logo={false} />} />
        </Switch>
      </>
    );
  }

  return (
    <>
      <SessionTimeout userType={userType} />

      <Wrapper userType={userType} route={route}>
        <Switch>
          <PageRoute
            page={'profile'}
            path={routePersonalProfile}
            render={(t) => <PagePersonalProfile userType={userType} {...t} />}
          />

          <Route
            path={route}
            render={() => {
              if (userType === 'individual') {
                return <AsyncIndividual route={route} />;
              }

              if (userType === 'company') {
                return <AsyncCompany route={route} />;
              }

              if (userType === 'aemi') {
                return <AsyncAemi route={route} acl={acl} />;
              }
            }}
          />

          <Route render={() => <PageNotFound logo={false} />} />
        </Switch>
      </Wrapper>
    </>
  );
};

export default tConsume(DashboardPage);

DashboardPage.propTypes = {
  verified: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};
