import isNumeric from 'validator/lib/isNumeric';
import { Strings } from 'HELPERS';
import { getLangPart } from 'TRANSLATION';
import { NUMBER_RULES } from 'FIELDS';

import msg from '../getErrorMsg';

const checkIsMountedAndHasContext = (context) =>
  Boolean(context && context.props && context.props.__tMounted);

export default (input, params, context) => {
  if (!isNumeric(input.value.toString())) {
    return msg(context, 'numeric');
  }

  if (input.hasOwnProperty(NUMBER_RULES.MORE_THAN)) {
    if (input.value <= input[NUMBER_RULES.MORE_THAN]) {
      if (checkIsMountedAndHasContext(context)) {
        return Strings.Compile(context.props.t('errors.mustBeMoreThan'), [
          input[NUMBER_RULES.MORE_THAN],
        ]);
      }
    }
  }

  if (input.hasOwnProperty(NUMBER_RULES.LESS_THAN)) {
    if (input.value >= input[NUMBER_RULES.LESS_THAN]) {
      if (checkIsMountedAndHasContext(context)) {
        return Strings.Compile(context.props.t('errors.mustBeLessThan'), [
          input[NUMBER_RULES.LESS_THAN],
        ]);
      }
    }
  }

  if (input.hasOwnProperty(NUMBER_RULES.MIN)) {
    if (input.value < input[NUMBER_RULES.MIN]) {
      if (checkIsMountedAndHasContext(context)) {
        return Strings.Compile(context.props.t('errors.tooSmall'), [input[NUMBER_RULES.MIN]]);
      }
    }
  }

  if (input.hasOwnProperty(NUMBER_RULES.MAX)) {
    if (input.value > input[NUMBER_RULES.MAX]) {
      if (checkIsMountedAndHasContext(context)) {
        return Strings.Compile(context.props.t('errors.tooLarge'), [input[NUMBER_RULES.MAX]]);
      }
    }
  }

  if (input.hasOwnProperty(NUMBER_RULES.LENGTH)) {
    if (input.value.trim().length < input[NUMBER_RULES.LENGTH]) {
      if (context && context.props && context.props.__tMounted) {
        return Strings.Compile(context.props.t('errors.tooShort'), [input[NUMBER_RULES.LENGTH]]);
      }

      return Strings.Compile(getLangPart('errors').tooShort, [input[NUMBER_RULES.LENGTH]]);
    }
  }

  return false;
};
