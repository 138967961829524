/**
 *
 * This file must contain reusable information.
 * Such as:
 ** formats
 ** special functions
 ** timers
 ** etc.
 *
 */
import PropTypes from 'prop-types';
import merge from 'deepmerge';

const version = require('../../package.json').version;
const configs = merge(require('ROOT/configs.json'), require('ROOT/configs.prod.json'), {
  arrayMerge: (target, source) => [...new Set([...target, ...source])],
});

import translation from './translation';

import regexp from './regexp';
import antd from './antd';

import isLocalhost from './defaults/is-localhost';
import routingReplace from './defaults/routing-replace';
import getRoute from './defaults/getRoute';
import isDevice from './defaults/is-device';
import isAuthorized from './defaults/is-authorized';
import countryByKey from './defaults/country-by-key';
import countryPhoneCode from './defaults/country-phone-code';
import countryList from './defaults/country-list';
import countryDefault from './defaults/country-default';
import currencyByKey from './defaults/currency-by-key';
import currencyFormat from './defaults/currency-format';
import { getMinAmountForCurrency } from './defaults/getMinAmountForCurrency';
import { getDecimalsForCurrency } from './defaults/getDecimalsForCurrency';
import date from './defaults/date';
import selectedItem from './defaults/selected-item';
import walletType from './defaults/wallet-types';
import { getCheckboxAnswer } from './defaults/getCheckboxAnswer';

import colorSchemes from './color-schemes';

import {
  accountNumberForDisplay,
  accountNumberMaskForInput,
  accountNumberForRequest,
  sortCodeForDisplay,
} from './formatter';

export default {
  walletType: walletType,
  gtm: 'GTM-NQCT68G',
  date: date,
  fileformats: ['pdf', 'jpg', 'jpeg', 'png'],
  fileSize: 30000,
  currency: {
    types: [
      {
        title: 'Euro',
        value: 'EUR',
      },
      {
        title: 'Pound Sterling',
        value: 'GBP',
      },
      {
        title: 'US Dollar',
        value: 'USD',
      },
    ],
    format: currencyFormat,
    currencyDefault: 'GBP',
    getCurrencyByKey: currencyByKey,
    getMinAmountForCurrency: getMinAmountForCurrency,
    getDecimalsForCurrency: getDecimalsForCurrency,
  },
  transaction: {
    types: [
      {
        title: 'PAYIN',
        value: 'PAYIN',
      },
      {
        title: 'PAYOUT',
        value: 'PAYOUT',
      },
      {
        title: 'FEE',
        value: 'FEE',
      },
    ],
  },
  colorSchemes: colorSchemes,
  formatter: {
    sortCodeForDisplay: sortCodeForDisplay,
    accountNumberForDisplay: accountNumberForDisplay,
    accountNumberMaskForInput: accountNumberMaskForInput,
    accountNumberForRequest: accountNumberForRequest,
  },
  localStorageKey: {
    tfaTimerEnd: 'tfaTimerEnd',
  },
  timer: 30, // seconds
  tfaTimer: 90, // seconds
  messageTimer: 120, // 2 minutes
  input: {
    minLength: 2,
    updateDelay: 500,
  },
  routing: {
    replace: routingReplace,
    getRoute: getRoute,
  },
  antd: antd,
  location: {
    phoneCode: countryPhoneCode,
    getCountryByKey: countryByKey,
    country: countryList,
    countryDefault: countryDefault,
  },
  translation: translation,
  validationError: 'Value not valid',
  re: regexp,
  version,
  selectedItem: selectedItem,
  isAuthorized: isAuthorized,
  isLocalhost: isLocalhost(window.location.hostname),
  isDevice: isDevice,
  getCheckboxAnswer: getCheckboxAnswer,
  ...configs,
};

configs.propTypes = {
  variables: {
    external_payments: PropTypes.bool,
    account_types: PropTypes.arrayOf(PropTypes.string),
    withTeidoWrapper: PropTypes.bool,
    divideCompanyNameIntoTwoLines: PropTypes.bool,
    rollbar_access_token: PropTypes.string,
  },
};

configs.defaultProps = {
  website: {
    title: '',
    home: {
      protocol: '',
      host: '',
    },
    company: '',
    email: '',
    terms_and_conditions: '',
    privacy_policy: '',
  },
  variables: {
    external_payments: false,
    account_types: ['company'],
    withTeidoWrapper: false,
    divideCompanyNameIntoTwoLines: false,
    rollbar_access_token: '',
  },
};
