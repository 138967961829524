import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import api from 'API';
import { clearUserToken } from './userTokenSlice';
import { withNotificationForError } from './helper';

export const sessionVerify = createAsyncThunk(
  'session/sessionVerify',
  withNotificationForError(async (payload, { getState }) => {
    const userType = getState().userToken.tokenType;

    const response = await axios(api.users.authorization.session(userType).AXIOS('get'));
    return response.data;
  })
);

export const sessionUpdate = createAsyncThunk(
  'session/sessionUpdate',
  withNotificationForError(async (payload, { getState }) => {
    const userType = getState().userToken.tokenType;

    const response = await axios(api.users.authorization.renew_token(userType).AXIOS('post'));
    return response.data;
  })
);

const initialState = {
  loading: false,
  visible: false,
  logout: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    sessionClose(state) {
      state.logout = true;
    },
    sessionModalClose(state) {
      state.visible = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sessionVerify.fulfilled, (state, action) => {
        if (action.payload.session_expires <= 0) {
          state.loading = false;
          state.visible = false;
          state.logout = true;
        }

        if (action.payload.session_expires <= 300) {
          state.visible = true;
          return;
        }

        state.visible = false;
      })
      .addCase(sessionVerify.rejected, (state) => {
        state.loading = false;
        state.visible = false;
        state.logout = true;
      })
      .addCase(sessionUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(sessionUpdate.fulfilled, (state) => {
        state.loading = false;
        state.visible = false;
      })
      .addCase(sessionUpdate.rejected, (state) => {
        state.loading = false;
        state.visible = false;
        state.logout = true;
      })
      .addCase(clearUserToken, () => {
        return initialState;
      });
  },
});

export const { sessionClose, sessionModalClose } = sessionSlice.actions;
export default sessionSlice.reducer;
