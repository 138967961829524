const NUMBER_RULES = {
  MIN: 'min',
  MAX: 'max',
  MORE_THAN: 'moreThan',
  LESS_THAN: 'lessThan',
  LENGTH: 'length',
};

const STRING_RULES = {
  MIN: 'min',
  MAX: 'max',
};

const MAX_TEXT_LENGTH = {
  TWENTY: 20,
  THIRTY_FIVE: 35,
  FIFTY: 50,
  SEVENTY: 70,
  ONE_HUNDRED: 100,
  ONE_HUNDRED_AND_FIFTY: 150,
  TWO_HUNDRED_AND_TEN: 210,
  TWO_HUNDRED_AND_FIFTY: 250,
  FIVE_THOUSAND: 5000,
};

export { NUMBER_RULES, STRING_RULES, MAX_TEXT_LENGTH };
