import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tConsume } from 'TRANSLATION';
import parseBool from '@common/helps/parseBool';
import { Form, Checkbox } from 'antd';
import setLabel from '@atoms/form/funcs/setLabel';

class FormCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: '',
      extra: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { t, item, withLabel } = this.props;
    const { value } = item;

    const label = withLabel ? setLabel(item, t) : '';

    this.setState({
      ...item,
      extra: t(`field.${item.name}.extra`, false),
      label: label,
    });

    this.handleChange(parseBool(value));
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props;
    if (item.value !== prevProps.item.value) {
      this.handleChange(parseBool(item.value));
    }
  }

  /**
   *
   * Calls parrent change method
   * @param {*} value
   *
   */
  handleChange(value) {
    const { item } = this.props;

    const newValue = {
      name: item.name,
      value: parseBool(value),
      help: '',
      status: '',
    };

    this.props.onChange(newValue);
  }

  render() {
    const { label, extra, name } = this.state;
    const { disabled, loading, item, withLabel, _static } = this.props;
    const { value, status, help, readOnly } = item;

    const checkbox = (
      <>
        <Checkbox
          checked={!!parseBool(value)}
          className={readOnly ? 'read-only' : ''}
          onChange={(event) => this.handleChange(event.target.checked)}
          disabled={_static || disabled || loading}
        >
          {extra}
        </Checkbox>
        <input type="hidden" name={name} value={parseBool(value)} />
      </>
    );

    return (
      <>
        {withLabel && (
          <Form.Item label={label} validateStatus={status} help={help}>
            {checkbox}
          </Form.Item>
        )}

        {!withLabel && checkbox}
      </>
    );
  }
}

export default tConsume(FormCheckbox);

FormCheckbox.propTypes = {
  /**
    name:           string,
    value:          bool,
    status:         string,
    defaultValue:   string,
  */
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  withLabel: PropTypes.bool,
  _static: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

FormCheckbox.defaultProps = {
  disabled: false,
  loading: false,
  withLabel: true,
};
