import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Menu, Icon, Badge } from 'antd';

import RenderLink from '@atoms/RenderLink/RenderLink';
// import LanguageChange from '@molecules/button/LanguageChange';
// import SupportMail from '@molecules/button/SupportMail';
import MessageNavigator from '@molecules/messages/MessageNavigator';
import UserAvatar from '@atoms/user-avatar';
import getDefaultKeys from '@common/helps/defaultKeys';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

const Topbar = ({ route, userType, t }) => {
  const { menuConfig } = useSelector((state) => state.menu);
  const personalData = useSelector((state) => state.personal.data);
  const unreadMessages = useSelector((state) => state.cases.unread);
  const location = useLocation();

  const { first_name, last_name } = personalData;
  const isDirector =
    personalData.profile_type === 'DIRECTOR' && personalData.company && personalData.company.name;

  const renderItem = (item) => {
    const { icon, name, subItems, path } = item;
    const key = `${item.name} - ${item.path ? item.path : ''}`;
    const _name = t(`menu.${name}`);

    if ('subItems' in item) {
      const inner = (
        <>
          {icon && <Icon type={icon || 'border'} />}
          <span>{_name}</span>
        </>
      );

      const title = path ? (
        <NavLink to={defaults.routing.getRoute(path, route)}>{inner}</NavLink>
      ) : (
        <span>{inner}</span>
      );

      return (
        <Menu.SubMenu key={key} title={title}>
          {renderMenu(subItems)}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item key={key} title={_name}>
        <RenderLink item={item} userType={userType} route={route} />
      </Menu.Item>
    );
  };

  const renderMenu = (items) => {
    return items.map((item) => renderItem(item));
  };

  const renderOverlay = () => {
    const { defaultOpenKeys, defaultSelectedKeys } = getDefaultKeys(menuConfig, location);

    return (
      <Menu
        className="dashboard-wrapper-menu"
        style={{ borderRight: 'none' }}
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={defaultSelectedKeys}
      >
        {menuConfig.bottom && menuConfig.bottom.map(renderItem)}
      </Menu>
    );
  };

  const renderName = () => {
    const { title, first_name, last_name, middle_name } = personalData;
    return first_name
      ? `${title ? `${title}.` : ''} ${first_name} ${middle_name || ''} ${last_name}`
      : '';
  };

  return (
    <div className="header">
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        overlay={<MessageNavigator route={route} userType={userType} />}
      >
        <Button type="link" size="large" className={'messages'}>
          <Badge count={unreadMessages.count}>
            <Icon type="mail" />
          </Badge>
        </Button>
      </Dropdown>

      {/* <SupportMail type="link" className="header-color" size={defaults.antd.button.size} /> */}

      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        disabled={!first_name}
        overlay={renderOverlay()}
      >
        <Button type="link" size="large" className={'profile'}>
          <UserAvatar name={`${first_name} ${last_name}`} />
          <span className="header-profileName">
            <span>
              {renderName()}
              {isDirector && (
                <>
                  <br />
                  Company: <b>{personalData.company.name}</b>
                </>
              )}
            </span>
            <Icon type="down" />
          </span>
        </Button>
      </Dropdown>

      {/* <LanguageChange
          type="link"
          className="header-color"
          size={defaults.antd.button.size}
          titleShort={true}
        /> */}
    </div>
  );
};

export default tConsume(Topbar);

Topbar.propTypes = {
  route: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
