import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';

import { withNotificationForError, checkPermissions } from './helper';

const initialState = {
  collapsed: false,
  device: 'phone',
  menuConfig: {
    top: [],
    bottom: [],
    additional: [],
  },
};

export const setRegisterConfig = createAsyncThunk(
  'menu/setRegisterConfig',
  withNotificationForError(async () => {
    return await require('../router/auth/menu').default;
  })
);

export const setMenuConfig = createAsyncThunk(
  'menu/setMenuConfig',
  withNotificationForError(async (location, { getState }) => {
    const acl = getState().acl.data.acl;
    const userType = getState().userToken.tokenType;
    const defaultMenuConfig = await require('../router/dashboard/' + userType + '/menu').default;
    let _defaultMenuConfig = cloneDeep(defaultMenuConfig);

    return {
      ..._defaultMenuConfig,
      top: checkPermissions(_defaultMenuConfig.top, acl),
    };
  })
);

const menuSlice = createSlice({
  name: 'menu',
  initialState: initialState,
  reducers: {
    toggleSidebar(state, action) {
      state.collapsed = action.payload.collapsed;
      state.device = action.payload.device || state.device;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setMenuConfig.fulfilled, (state, action) => {
        state.menuConfig = action.payload;
      })
      .addCase(setMenuConfig.rejected, (state) => {
        state.menuConfig = initialState.menuConfig;
      })
      .addCase(setRegisterConfig.fulfilled, (state, action) => {
        state.menuConfig = action.payload;
      })
      .addCase(setRegisterConfig.rejected, (state) => {
        state.menuConfig = initialState.menuConfig;
      });
  },
});

export const { toggleSidebar } = menuSlice.actions;
export default menuSlice.reducer;
