import isAlpha from 'validator/lib/isAlpha';
import msg from '../getErrorMsg';

export default (input, params, context) => {
  if (!isAlpha(input.value, params.locale, { ignore: params.ignore })) {
    return msg(context, 'notAlpha');
  }

  return false;
};
