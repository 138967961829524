import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import { Table } from 'antd';

import FieldsControl from './FieldsControl';
import Spinner from '@atoms/spinner';

const CreateTable = ({
  fieldsControl = true,
  columns = [],
  loading = false,
  pagination = false,
  columnResize = false,
  dataSource = [],
  expandRowByClick = false,
  expandIcon = null,
  expandedRowRender = null,
  rowKey = 'key',
  filters = {},
  onChange = () => {},
}) => {
  const [fieldsList, setFieldsList] = useState([]);
  const [activeFields, setActiveFields] = useState([]);
  const [activeColumns, setActiveColumns] = useState([]);

  useEffect(() => {
    update();

    const debounceUpdate = debounce(update, 200);
    window.addEventListener('resize', debounceUpdate);

    return () => {
      window.removeEventListener('resize', debounceUpdate);
    };
  }, []);

  useEffect(() => {
    updateColumns();
  }, [activeFields]);

  const update = () => {
    updateColumns();
    updateFieldsList();
  };

  const updateColumns = () => {
    const _columns = columns
      .filter(({ condition }) => (typeof condition !== 'undefined' ? !!condition : true))
      .filter((item, index) => {
        if (activeFields.length > 0 && activeFields[index] !== null) {
          return activeFields[index];
        }

        return !isNeedToHideColumn(item);
      });

    setActiveColumns(_columns);
  };

  const updateFieldsList = () => {
    const _fieldsList = columns
      .filter(({ condition }) => (typeof condition !== 'undefined' ? !!condition : true))
      .map((item) => {
        const value = !isNeedToHideColumn(item);

        return { title: item.title, dataIndex: item.dataIndex, value: value };
      });

    setFieldsList(_fieldsList);

    if (activeFields.length === 0) {
      setActiveFields(_fieldsList.map(() => null));
    }
  };

  const isNeedToHideColumn = (item) =>
    !!((item.matchMedia && matchMedia(item.matchMedia)) || item.hidden);

  const matchMedia = (width) => window.matchMedia(`(max-width: ${width}px)`).matches;

  const compareFieldsState = () => {
    return fieldsList.map((item, index) => {
      if (activeFields[index] !== null) {
        return { ...item, value: activeFields[index] };
      }

      return item;
    });
  };

  const handleChangeFields = (index) => {
    const _activeFields = [...activeFields];
    _activeFields[index] =
      activeFields[index] !== null ? !activeFields[index] : !fieldsList[index].value;

    setActiveFields(_activeFields);
  };

  const handleTableChange = async (pagination, defaultFilters, sorter) => {
    let filterConfig = {};

    // pagination
    filterConfig.page = pagination.current;

    // filters
    for (let filterKey in filters) {
      if (filters.hasOwnProperty(filterKey)) {
        filterConfig[filterKey] = filters[filterKey].toString();
      }
    }

    // sorter
    if (sorter.hasOwnProperty('order')) {
      filterConfig.sort = `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`;
    }

    await onChange(filterConfig);
  };

  // pagination
  const renderShowTotal = (total, range) => (
    <span>{`${range[0]}-${range[1]} of ${total} items`}</span>
  );

  let _pagination = false;
  if (pagination) {
    _pagination = {
      ...pagination,
      hideOnSinglePage: pagination.hideOnSinglePage || true,
      showTotal: renderShowTotal,
    };
  }

  return (
    <>
      {fieldsControl && (
        <FieldsControl fields={compareFieldsState()} handleFields={handleChangeFields} />
      )}
      <Table
        columns={activeColumns}
        loading={{ spinning: loading, indicator: <Spinner /> }}
        pagination={_pagination}
        columnResize={columnResize}
        dataSource={dataSource}
        expandRowByClick={expandRowByClick}
        expandIcon={expandIcon}
        expandedRowRender={expandedRowRender}
        rowKey={rowKey}
        onChange={handleTableChange}
      />
    </>
  );
};

export default CreateTable;

CreateTable.propTypes = {
  fieldsControl: PropTypes.bool,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  columnResize: PropTypes.bool,
  dataSource: PropTypes.array,
  expandRowByClick: PropTypes.bool,
  expandIcon: PropTypes.elementType,
  expandedRowRender: PropTypes.elementType,
  rowKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  filters: PropTypes.object,
  onChange: PropTypes.func,
};
